<template>
  <div>
    <p>{{ $t('onboarding.personalData.desc') }}</p>
    <v-form
      v-model="validForm"
      @submit.prevent="submit"
    >
      <!-- GENDER -->
      <v-btn-toggle
        :class="['mt-5', 'mb-10', $vuetify.theme.dark ? 'gender-dark' : 'gender-light']"
        v-model="data.personalData.gender"
        rounded
      >
        <v-btn
          v-for="gender in genders"
          :key="gender.value"
          :value="gender.value"
          :class="`${data.personalData.gender === gender ? 'active' : ''}`"
        >
          {{ gender.text }}
        </v-btn>
      </v-btn-toggle>
      <!-- FIRST NAME -->
      <v-text-field
        class="mt-5"
        id="firstName"
        type="text"
        :rules="rules.name"
        v-model="data.personalData.firstName"
        autocomplete="first-name"
        required
        :label="`${$t('onboarding.personalData.fields.firstName')} *`"
        outlined
        validate-on-blur
        ref="firstName"
        @input="checkValidation('firstName')"
      ></v-text-field>

      <!-- LAST NAME -->
      <v-text-field
        id="lastName"
        type="text"
        :rules="rules.name"
        v-model="data.personalData.lastName"
        autocomplete="last-name"
        required
        :label="`${$t('onboarding.personalData.fields.lastName')} *`"
        outlined
        validate-on-blur
        ref="lastName"
        @input="checkValidation('lastName')"
      ></v-text-field>

      <!-- EDUCATION DEGREE -->
      <v-select
        id="educationDegree"
        :items="educationDegrees"
        item-text="text"
        item-value="value"
        v-model="data.personalData.educationDegree"
        :rules="rules.required"
        :label="`${$t('onboarding.personalData.fields.educationDegree')} *`"
        outlined
    ></v-select>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import clone from '@/utils/clone';
import { name, required } from '@/utils/validators';

export default {
  name: 'PersonalData',
  data() {
    return {
      data: clone(this.$store.state.user.data),
      rules: {
        name,
        required,
      },
      validForm: true,
    };
  },
  computed: {
    ...mapGetters('onboarding', [
      'genders',
      'educationDegrees',
      'editMode',
    ]),
    ...mapFields('onboarding', [
      'isFormValid',
    ]),
  },
  watch: {
    'data.personalData': {
      handler(newVal) {
        this.$refs.firstName.validate();
        this.$refs.lastName.validate();
        this.validation(newVal);
      },
      deep: true,
    },
  },
  created() {
    this.validation(this.data.personalData);
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'setSubStage',
      'updateOnboarding',
    ]),
    ...mapActions('onboarding', [
      'setFormValidity',
    ]),
    checkValidation(refName) {
      if (!this.isFormValid) {
        this.$refs[refName].validate();
      }
    },
    validation(personalData) {
      const {
        gender, firstName, lastName, educationDegree,
      } = personalData;
      if (gender && firstName && lastName && educationDegree
        && this.$refs.firstName.validate() && this.$refs.lastName.validate()) {
        this.setFormValidity(true);
      } else {
        this.setFormValidity(false);
      }
    },
    async submit() {
      const input = {
        investor: {
          personal: {
            personalData: {
              firstName: this.data.personalData.firstName.trim(),
              lastName: this.data.personalData.lastName.trim(),
              gender: this.data.personalData.gender,
              educationDegree: this.data.personalData.educationDegree,
            },
          },
        },
        mainStage: 'personal',
        subStage: 'personalData',
      };
      try {
        await this.updateOnboarding({
          input,
          newData: this.data,
        });
        if (this.editMode) {
          this.$store.dispatch('onboarding/editMode', null);
          this.$router.push({
            name: 'Onboarding.Personal',
            params: {
              step: 'Overview',
            },
          });
        } else {
          this.setSubStage('generalinformation');
        }
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss">
.gender-light {
  border: 2px solid #CEB390;
  text-transform: uppercase;
  display: flex;

  .v-btn {
    width: 50%;
    background: #FCFCFC !important;
    font-weight: 900;
  }
  .active {
    color: #000 !important;
    background: #E6C8A0 !important;
  }
}
.gender-dark {
  border: 2px solid #CEB390;
  text-transform: uppercase;
  display: flex;

  .v-btn {
    width: 50%;
    background: #192128 !important;
    font-weight: 900;
  }
  .active {
    color: #000 !important;
    background: #E6C8A0 !important;
  }
}
</style>
